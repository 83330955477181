/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'gender-female': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M8 1a4 4 0 100 8 4 4 0 000-8M3 5a5 5 0 115.5 4.975V12h2a.5.5 0 010 1h-2v2.5a.5.5 0 01-1 0V13h-2a.5.5 0 010-1h2V9.975A5 5 0 013 5"/>',
    },
});
